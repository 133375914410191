import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { timer } from 'rxjs';
import { differenceInMinutes, format } from 'date-fns';
// Utils
import { apiRequest, apiRequestError, apiURL } from '../util/api';
import {
  jsonConvert,
  getUTCDate,
  isNil,
  map as ramdaMap,
  getWSCallKey,
  pluck,
  equals
} from '../util/general';
import { getACObservable, unsubscribeActionCable } from '../util/ActionCableObservable';
import { getSearchTypeFromSearch } from '../util/app';
import { isRentalAndPickupSame, getSearchTypesForBE } from '../util/search';
import { setTimeToDate } from '../util/filters';
// Constants
import { API_URL, API_METHOD_TYPE, WS_CHANNELS, WS_ACTIONS } from '../const/api';
import { SEARCH_TYPE, DEBOUNCE_TIME, STATUS, HOTEL_SORT_VALUES } from '../const/app';
import { LOCATION_TYPES } from '@toolkit/const/app';
// Actions
import {
  searchActions,
  filterActions,
  tripsActions,
  hotelActions,
  settingsActions,
  generalActions,
  checkoutActions,
} from '../actions';
// Models
import {
  SearchModel,
  TravelSearchAggregatorModel,
  PassengerModel,
  EventListingModel,
} from '../models';
// Interfaces
import { RootAction, RootState } from '../interfaces';

const mapAggregatorsArr = ramdaMap((agg: any) => jsonConvert.deserialize(agg, TravelSearchAggregatorModel));
const datetoJSONFormat = `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`;
const getReqSearchDepName = (search: SearchModel) => {
  switch (search.searchType) {
    case SEARCH_TYPE.HOTEL:
      return search.arrName;
    default:
      return search.depName;
  }
}

const getReqSearchArrName = (search: SearchModel) => {
  switch (search.searchType) {
    case SEARCH_TYPE.RENTAL:
      return isRentalAndPickupSame(search) ? search.depName : search.arrName;
    default:
      return search.arrName;
  }
}
const getReqSearchDep1Name = (search: SearchModel) => {
  switch (search.searchType) {
    case SEARCH_TYPE.HOTEL:
      return search.arrName1;
    default:
      return search.depName1;
  }
}

const getReqSearchArr1Name = (search: SearchModel) => {
  switch (search.searchType) {
    case SEARCH_TYPE.RENTAL:
      return isRentalAndPickupSame(search) ? search.depName1 : search.arrName1;
    default:
      return search.arrName1;
  }
}
export const initSearchEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(searchActions.initSearch)),
    switchMap((action) => {
      const searchObservable = getACObservable(WS_CHANNELS.SEARCH, action.payload.searchId, WS_ACTIONS.REBROADCAST);

      return searchObservable.pipe(
        switchMap((res: any) => {
          let actionArr: RootAction[] = [];

          const hotelSearches: TravelSearchAggregatorModel[] = mapAggregatorsArr(res.hotel_searches);
          const transportSearches: TravelSearchAggregatorModel[] = mapAggregatorsArr(res.transport_searches);

          res.travel_search['dep1_geocoded_name'] = ''
          res.travel_search['arr1_geocoded_name'] = ''
          res.travel_search['dep1_lat'] = -1
          res.travel_search['dep1_lng'] = -1
          res.travel_search['arr1_lat'] = -1
          res.travel_search['arr1_lng'] = -1

          const search: SearchModel = jsonConvert.deserializeObject(res.travel_search, SearchModel);

          const isSearchingForOnlyTransport = equals(search.types, ['transport']);
          const isSearchingForOnlyHotel = equals(search.types, ['hotel']);
          const isSearchingForOnlyRental = equals(search.types, ['rental']);

          // TODO: get rid of this condition, there is some SessionRouter stuff that relies on this (regarding passengers)
          // but eventually we should separate that and get rid of this condition as this is not giving us the actual
          // state of the SearchStatus channel
          if (store$.value.search.currentSearch.id !== res.travel_search.id) {
            actionArr = [
              settingsActions.setWSCallEnd(getWSCallKey(action.type, search.id)),
              searchActions.setSearch(search),
              searchActions.setId(search.id),
              settingsActions.setIsSearchingForOnlyOutbound(isSearchingForOnlyTransport && isNil(search.arrAt)),
              settingsActions.setIsSearchingForOnlyHotel(isSearchingForOnlyHotel),
              settingsActions.setIsSearchingForOnlyRental(isSearchingForOnlyRental),
              settingsActions.setIsSearchingForOnlyTransport(isSearchingForOnlyTransport),
              searchActions.setSearchType(getSearchTypeFromSearch(search)),
              generalActions.applyExtActionAsync.request(
                { callback: action.payload.onSuccess, param: search }),
              settingsActions.startWSCheck(),
            ];
          }

          if (res.travel_search.events_status === STATUS.EVENTS_FINISHED
             && store$.value.search?.currentSearch?.eventsStatus !== res.travel_search.events_status) {
            actionArr = actionArr.concat(
              searchActions.setSearchEventsStatus(res.travel_search.events_status),
              searchActions.fetchEventsAsync.request({
                onError: action.payload.onError,
                searchId: res.travel_search.id
              })
            )
          }

          // we set the user "preferred" hotel sorting to `distance` if they choose a hotel from suggestions
          // otherwise we set the value from their preference
          const userHotelSorting = isSearchingForOnlyHotel && search.arrLocType === LOCATION_TYPES.LODGING
              ? HOTEL_SORT_VALUES.DISTANCE
              : store$.value.adminUser.profile?.preference?.hotelSorting;

          actionArr = actionArr.concat(
            hotelSearches.map((hotelAg) => searchActions.addHotelSearchAggregator(hotelAg)),
            transportSearches.map((transportAg) => searchActions.addTransportSearchAggregator(transportAg)),
            filterActions.setHotelSortingUserFilter({ current: userHotelSorting }),
            searchActions.setLastWSUpdated(Date.now())
          );

          return actionArr;
        })
      );
    })
  );

export const startSearchEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(searchActions.startSearchAsync.request)),
    switchMap((action) => {
      const { search } = action.payload;
      // Only set depTime & arrTime for rental searches
      // transport and hotel searches should start at 00:00
      const [depAtHours, depAtMinutes] = search.searchType === SEARCH_TYPE.RENTAL
        ? search.depRentalTime.split(':').map(Number)
        : [0, 0];
      const [arrAtHours, arrAtMinutes] = search.searchType === SEARCH_TYPE.RENTAL
        ? search.arrRentalTime.split(':').map(Number)
        : [0, 0];
      const depAt = setTimeToDate(depAtHours, depAtMinutes)(search.depAt);
      const arrAt = setTimeToDate(arrAtHours, arrAtMinutes)(search.arrAt);

      const reqData: any = {
        transport_search: {
          passenger_user_ids: pluck('userId', search.passengers),
          room_type: search.roomType,
          dep_loc_type: search.depLocType,
          arr_loc_type: search.arrLocType,
          types: getSearchTypesForBE(search),
          flight_cabin_class: search.flightCabinClass,
          sections_attributes: [
            {
              dep_at: format(depAt, datetoJSONFormat),
              dep_lat: (search.searchType === SEARCH_TYPE.HOTEL) ? null : search.depLat,
              dep_lng: (search.searchType === SEARCH_TYPE.HOTEL) ? null : search.depLng,
              arr_lat: isRentalAndPickupSame(search) ? search.depLat : search.arrLat,
              arr_lng: isRentalAndPickupSame(search) ? search.depLng : search.arrLng,
              dep_geocoded_name: getReqSearchDepName(search),
              arr_geocoded_name: getReqSearchArrName(search),
            },
            {
              dep_at: search.searchType === SEARCH_TYPE.OUTBOUND ? null : format(arrAt, datetoJSONFormat),
              dep_lat: (search.searchType === SEARCH_TYPE.HOTEL) ? null : search.dep1Lat,
              dep_lng: (search.searchType === SEARCH_TYPE.HOTEL) ? null : search.dep1Lng,
              arr_lat: (search.searchType === SEARCH_TYPE.HOTEL) ? null : search.arr1Lat,
              arr_lng: (search.searchType === SEARCH_TYPE.HOTEL) ? null : search.arr1Lng,
              dep_geocoded_name: getReqSearchDep1Name(search),
              arr_geocoded_name: getReqSearchArr1Name(search),
            }
          ]
        },
        better_pathfinding: true,
      };

      if (store$.value.search.currentSearch.rebooking) {
        reqData['rebooking'] = {
          original_travel_booking_item_id: store$.value.search.currentSearch.rebooking.originalTravelBookingItemId,
        };

        if (store$.value.search.currentSearch.rebooking.originalFareId !== -1) {
          reqData['rebooking']['original_fare_id'] = store$.value.search.currentSearch.rebooking.originalFareId;
          reqData['rebooking']['original_fare_type'] = store$.value.search.currentSearch.rebooking.originalFareType;
        }
      }

      return apiRequest(API_URL.TRANSPORT_SEARCHES_GET, API_METHOD_TYPE.POST, reqData)
        .pipe(
          mergeMap((res: any) => {
            unsubscribeActionCable();

            if (search.searchType === SEARCH_TYPE.RENTAL) {
              return [
                generalActions.applyExtActionAsync.request(
                  { callback: action.payload.onSuccess, param: { id: res.response.id, type: 'travel' } })]
            }

            return [
              searchActions.clearTransportSearchesAggregators(),
              searchActions.clearHotelSearchesAggregators(),
              settingsActions.clearWSCalls(),
              filterActions.clearFilters(),
              tripsActions.clearTrips(),
              hotelActions.clearHotels(),
              checkoutActions.clearBasket(),
              searchActions.setId(res.response.id),
              searchActions.initSearch({
                onError: action.payload.onError,
                onSuccess: action.payload.onSuccess,
                searchId: res.response.id,
              }),
            ];
          }),
          catchError((err) => apiRequestError(action.payload.onError, 'StartSearchError', err))
        );
    })
  );

export const checkSearchExpirationEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(searchActions.setSearch)),
    switchMap((action) => {
      return timer(0, 60000)
        .pipe(
          filter(() => (differenceInMinutes(getUTCDate(), action.payload.createdAt) >= 30)),
          map(() => searchActions.setSearchExpiration(true)),
          takeUntil(action$.ofType(searchActions.setSearchExpiration))
        );
    })
  );

export const fetchPassengersSuggestionsEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(searchActions.fetchPassengersSuggestionsAsync.request)),
    switchMap((action) =>
      apiRequest(encodeURI(`${API_URL.USERS}/autocomplete?bookable_as_passenger=true&term=${action.payload.searchTerm}`))
        .pipe(
          debounceTime(DEBOUNCE_TIME.PASSENGERS_SUGGESTIONS),
          mergeMap((res: any) => {
            const passengers = res.response.users.map((user: any) => {
              const passenger: PassengerModel = new PassengerModel();
              passenger.userId = user.id;
              passenger.firstName = user.first_name;
              passenger.lastName = user.last_name;
              passenger.role = user.role;
              return passenger;
            });
            return [
              searchActions.setPassengersSuggestions(passengers),
              generalActions.applyExtActionAsync.request({ callback: action.payload.onSuccess, param: null }),
            ];
          }),
          catchError((err) => apiRequestError(action.payload.onError, 'FetchPassengersSuggestionsError', err))
        )
    )
  );

export const fetchEventsEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(searchActions.fetchEventsAsync.request)),
    switchMap((action) =>
      apiRequest(
        apiURL.search.fetchEvents(action.payload.searchId))
        .pipe(
          map(({ response }) => {
            const eventListing = jsonConvert.deserializeObject(response, EventListingModel);
            return searchActions.setSearchEventListing(eventListing);
          }),
          catchError((err) => apiRequestError(action.payload.onError, 'FetchEventsError', err))
        )
    )
  );
