import * as React from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { AppContext } from '@toolkit/util/AppContext';
import { searchHooks } from '@src/services';
// Constants
import { SEARCH_TYPE } from '@src/shared/src/const/app';
// Actions
import { searchActions } from '@src/shared/src/actions';
// Models
import { models } from '@src/shared/src';
// Interfaces
// Components
import SearchBarSlideOutNavbar from '@src/pod/search/components/SearchBarSlideOutNavbar';
import {
  SearchBarRouteInput,
  SearchBarSlideOut,
  SearchBarRouteSelector,
  RentalOnlySearch,
} from '@pod/search/components';
// Styles
import '@pod/search/styles/SearchBarOptions.scss';

type Props = {
  isSearchingForOnlyHotel: boolean;
  uiSearchSuggestDepOverlay: boolean;
  uiSearchSuggestArrOverlay: boolean;
  uiSearchSuggestDep1Overlay: boolean;
  uiSearchSuggestArr1Overlay: boolean;
  depOutboundSuggestions: models.SearchNodeModel[];
  arrOutboundSuggestions: models.SearchNodeModel[];
  depInboundSuggestions: models.SearchNodeModel[];
  arrInboundSuggestions: models.SearchNodeModel[];
  homeLocations: models.OptionModel;
  workLocations: models.OptionModel;
  favoriteLocations: models.OptionModel[];
  onSetUiSearchSuggestDepOverlay: (val: string, type?: boolean) => void;
  onSetUiSearchSuggestArrOverlay: (val: string, type?: boolean) => void;
  onSetUiSearchSuggestDep1Overlay: (val: string, type?: boolean) => void;
  onSetUiSearchSuggestArr1Overlay: (val: string, type?: boolean) => void;
  onSetIsSearchingForOnlyHotel: (val: boolean) => void;
  onSetIsSearchingForOnlyOutbound: (val: boolean) => void;
  onFetchLocationDetail: (isDep: boolean, index: number, location: models.SearchNodeModel) => void;
  profile: models.UserModel;
  transportInputDisabled?: boolean;
};

export const SearchBarRoute: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const [searchType] = searchHooks.useSearchState<SEARCH_TYPE>(['currentSearch', 'searchType']);
  const [searchArr] = searchHooks.useSearchState<string>(['currentSearch', 'arrName']);
  const [searchDep] = searchHooks.useSearchState<string>(['currentSearch', 'depName']);
  const [searchArr1] = searchHooks.useSearchState<string>(['currentSearch', 'arrName1']);
  const [searchDep1] = searchHooks.useSearchState<string>(['currentSearch', 'depName1']);
  const [rentalReturnAtPickup] = searchHooks.useSearchState<boolean>([
    'currentSearch',
    'rentalReturnAtPickup',
  ]);
  const isArrivalMissing = searchHooks.useIsArrMissing();
  const isDepartureMissing = searchHooks.useIsDepMisisng();

  const setSearchArrival = (val: string) => {
    dispatch(searchActions.setSearchArrCoor(-1, -1));
    dispatch(searchActions.setSearchArr(val));
  };

  const setSearchDeparture = (val: string) => {
    dispatch(searchActions.setSearchDepCoor(-1, -1));
    dispatch(searchActions.setSearchDep(val));
  };

  const setSearchArrival1 = (val: string) => {
    dispatch(searchActions.setSearchArr1Coor(-1, -1));
    dispatch(searchActions.setSearchArr1(val));
  };

  const setSearchDeparture1 = (val: string) => {
    dispatch(searchActions.setSearchDep1Coor(-1, -1));
    dispatch(searchActions.setSearchDep1(val));
  };

  const renderSearchBarRouteInput = (
    type: string,
    searchType: SEARCH_TYPE,
    inNavbar: boolean = false,
    index: number
  ) => {
    switch (searchType) {
      case SEARCH_TYPE.HOTEL:
        return (
          <div className="tcp-search-bar-route tcp-search-bar-route-active">
            <SearchBarRouteInput
              index={index}
              isFrom={false}
              value={searchArr}
              onFocus={() => props.onSetUiSearchSuggestArrOverlay('arrSuggest')}
              onChange={setSearchArrival}
              displayErrorMsg={isArrivalMissing}
              errorMessage={t('searchBarRoute.form.input.hotelArrival.errorMessage')}
              placeholder={t('search.bar.form.input.placeholder.location')}
              inSlideout={type ? true : false}
            />
          </div>
        );
      case SEARCH_TYPE.RENTAL:
        return (
          <RentalOnlySearch
            onSetSearchArr={setSearchArrival}
            onSetSearchDep={setSearchDeparture}
            onSetUiSearchSuggestArrOverlay={props.onSetUiSearchSuggestArrOverlay}
            onSetUiSearchSuggestDepOverlay={props.onSetUiSearchSuggestDepOverlay}
            isDepartureMissing={isDepartureMissing}
            isArrivalMissing={isArrivalMissing}
            uiSearchSuggestArrOverlay={props.uiSearchSuggestArrOverlay}
            uiSearchSuggestDepOverlay={props.uiSearchSuggestDepOverlay}
            slideOutType={type}
            searchArr={searchArr}
            searchDep={searchDep}
            returnAtPickup={rentalReturnAtPickup}
            setReturnAtPickup={(val) => dispatch(searchActions.setSearchRentalReturnAtPickup(val))}
            inNavbar={inNavbar}
          />
        );
      default:
        return (
          <>
            {(!type || type === 'outbound') && index == 0 ? (
              <SearchBarRouteInput
                index={0}
                isFrom={true}
                value={searchDep}
                onFocus={() => props.onSetUiSearchSuggestDepOverlay('depSuggest')}
                onChange={setSearchDeparture}
                placeholder='out0'
                displayErrorMsg={false}
                errorMessage={t('searchBarConn.notify.text.departureMissing')}
                isVisible={props.uiSearchSuggestDepOverlay}
                inSlideout={type ? true : false}
                disabled={props.transportInputDisabled}
              />
            ) : (
              ''
            )}
            {(!type || type === 'inbound') && index == 0 ? (
              <SearchBarRouteInput
                index={0}
                isFrom={false}
                value={searchArr}
                onFocus={() => props.onSetUiSearchSuggestArrOverlay('arrSuggest')}
                onChange={setSearchArrival}
                placeholder='in0'
                displayErrorMsg={false}
                errorMessage={t('searchBarConn.notify.text.arrivalMissing')}
                isVisible={props.uiSearchSuggestArrOverlay}
                inSlideout={type ? true : false}
                disabled={props.transportInputDisabled}
              />
            ) : (
              ''
            )}
            {(!type || type === 'outbound') && index == 1 ? (
              <SearchBarRouteInput
                index={1}
                isFrom={true}
                value={searchDep1}
                onFocus={() => props.onSetUiSearchSuggestDep1Overlay('dep1Suggest')}
                onChange={setSearchDeparture1}
                placeholder='out1'
                displayErrorMsg={false}
                errorMessage={t('searchBarConn.notify.text.departureMissing')}
                isVisible={props.uiSearchSuggestDep1Overlay}
                inSlideout={type ? true : false}
                disabled={props.transportInputDisabled}
              />
            ) : (
              ''
            )}
            {(!type || type === 'inbound') && index == 1 ? (
              <SearchBarRouteInput
                index={1}
                isFrom={false}
                value={searchArr1}
                onFocus={() => props.onSetUiSearchSuggestArr1Overlay('arr1Suggest')}
                onChange={setSearchArrival1}
                placeholder='in1'
                displayErrorMsg={false}
                errorMessage={t('searchBarConn.notify.text.arrivalMissing')}
                isVisible={props.uiSearchSuggestArr1Overlay}
                inSlideout={type ? true : false}
                disabled={props.transportInputDisabled}
              />
            ) : (
              ''
            )}
          </>
        );
    }
  };

  const onSuggestionSelected = (isDep: boolean, index: number, location: models.SearchNodeModel) => {
    // onSetUiSearchSuggestDepOverlay and onSetUiSearchSuggestArrOverlay are same func
    // TODO: Should we pass just one?
    props.onFetchLocationDetail(isDep, index, location);
    props.onSetUiSearchSuggestDepOverlay('');
  };

  return (
    <div className="tcp-search-bar-route">
      {renderSearchBarRouteInput(null, searchType, false, 0)}

      <SearchBarSlideOut isOpen={props.uiSearchSuggestDepOverlay} className="is--route">
        {!appContext.isMediaBPNotMobile ? (
          <>
            <SearchBarSlideOutNavbar
              onClose={() => props.onSetUiSearchSuggestDepOverlay('')}
              hideConfirmButton
            />
            {renderSearchBarRouteInput('outbound', searchType, true, 0)}
          </>
        ) : null}
        <SearchBarRouteSelector
          isDep={true}
          searchStr={searchDep}
          suggestions={props.depOutboundSuggestions}
          homeLocations={props.homeLocations}
          workLocations={props.workLocations}
          favoriteLocations={props.favoriteLocations}
          onSuggestionSelected={(val) => onSuggestionSelected(true, 0, val)}
          profileId={props.profile.id}
        />
      </SearchBarSlideOut>
      <SearchBarSlideOut isOpen={props.uiSearchSuggestArrOverlay} className="is--route">
        {!appContext.isMediaBPNotMobile ? (
          <>
            <SearchBarSlideOutNavbar
              onClose={() => props.onSetUiSearchSuggestArrOverlay('')}
              hideConfirmButton
            />
            {renderSearchBarRouteInput('inbound', searchType, true, 0)}
          </>
        ) : null}
        <SearchBarRouteSelector
          isDep={false}
          searchStr={searchArr}
          suggestions={props.arrOutboundSuggestions}
          homeLocations={props.homeLocations}
          workLocations={props.workLocations}
          favoriteLocations={props.favoriteLocations}
          onSuggestionSelected={(val) => onSuggestionSelected(false, 0, val)}
          profileId={props.profile.id}
        />
      </SearchBarSlideOut>
      {renderSearchBarRouteInput(null, searchType, false,1)}

      <SearchBarSlideOut isOpen={props.uiSearchSuggestDep1Overlay} className="is--route">
        {!appContext.isMediaBPNotMobile ? (
          <>
            <SearchBarSlideOutNavbar
              onClose={() => props.onSetUiSearchSuggestDep1Overlay('')}
              hideConfirmButton
            />
            {renderSearchBarRouteInput('outbound', searchType, true, 1)}
          </>
        ) : null}
        <SearchBarRouteSelector
          isDep={true}
          searchStr={searchDep1}
          suggestions={props.depInboundSuggestions}
          homeLocations={props.homeLocations}
          workLocations={props.workLocations}
          favoriteLocations={props.favoriteLocations}
          onSuggestionSelected={(val) => onSuggestionSelected(true, 1, val)}
          profileId={props.profile.id}
        />
      </SearchBarSlideOut>
      <SearchBarSlideOut isOpen={props.uiSearchSuggestArr1Overlay} className="is--route">
        {!appContext.isMediaBPNotMobile ? (
          <>
            <SearchBarSlideOutNavbar
              onClose={() => props.onSetUiSearchSuggestArr1Overlay('')}
              hideConfirmButton
            />
            {renderSearchBarRouteInput('inbound', searchType, true, 1)}
          </>
        ) : null}
        <SearchBarRouteSelector
          isDep={false}
          searchStr={searchArr1}
          suggestions={props.arrInboundSuggestions}
          homeLocations={props.homeLocations}
          workLocations={props.workLocations}
          favoriteLocations={props.favoriteLocations}
          onSuggestionSelected={(val) => onSuggestionSelected(false, 1, val)}
          profileId={props.profile.id}
        />
      </SearchBarSlideOut>
    </div>
  );
};
export default SearchBarRoute;
