import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

// Utils
import { navigate, baseErrorCond } from '@toolkit/util/app';
import { cond, isEmpty, isNil, verifyProperty, hasEmptyValues } from '@src/shared/src/util/general';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';

// Constants
import { DIRECTION, SEARCH_TYPE } from '@src/shared/src/const/app';
import { API_URL } from '@src/shared/src/const/api';
import { ROUTES } from '@toolkit/const/app';
// Actions, Models & Interfaces
import { selectors } from '@src/shared/src';
import { adminUserSelectors, searchSelectors } from '@src/shared/src/selectors';
import { searchActions, settingsActions } from '@src/shared/src/actions';
import {
  SearchNodeModel,
  UserModel,
  SearchModel,
  CoordinatesModel,
  PassengerModel,
  OrganizationModel,
} from '@src/shared/src/models';
import { IConnectedRedux, IRootState } from '@src/store';
import {
  setUiSearchArrSuggestOverlay,
  setUiSearchDepSuggestOverlay,
  setUiSearchArr1SuggestOverlay,
  setUiSearchDep1SuggestOverlay,
  setUiSearchDepAtOverlay,
  setUiSearchArrAtOverlay,
  setUiSearchPassengersOverlay,
  setUiSearchConciergeOverlay,
} from '@pod/ui/actions';
import {
  uiSearchArrSuggestOverlay,
  uiSearchDepSuggestOverlay,
  uiSearchArr1SuggestOverlay,
  uiSearchDep1SuggestOverlay,
  uiSearchDepAtOverlay,
  uiSearchArrAtOverlay,
  uiSearchConciergeOverlay,
  uiSearchPassengersOverlay,
} from '@pod/ui/selectors';
import { isSearchingForOnlyHotel, isSearchingForOnlyOutbound } from '@pod/settings/selectors';
import { IFilterTimeWindow } from '@src/shared/src/interfaces';
// Components
import {
  SearchBarDateTime,
  SearchBarFilter,
  SearchBarPassengers,
  SearchBarConcierge,
  SearchBarRoute,
  SearchSubmitButton,
  SearchOptions,
} from '@pod/search/components';
import { Logo } from '@pod/header/components';
import { BENotificationBar, RebookingBanner } from '@toolkit/ui';
// Styles
import '../styles/SearchBar.scss';

type Props = IConnectedRedux<IRootState> &
  WithTranslation &
  AppContextProp & {
    search: SearchModel;
    depOutboundSuggestions: SearchNodeModel[];
    arrOutboundSuggestions: SearchNodeModel[];
    depInboundSuggestions: SearchNodeModel[];
    arrInboundSuggestions: SearchNodeModel[];
    depCoor: CoordinatesModel;
    arrCoor: CoordinatesModel;
    depAt: Date;
    arrAt: Date;
    isSearchingForOnlyHotel: boolean;
    isSearchingForOnlyOutbound: boolean;
    uiSearchDepSuggestOverlay: boolean;
    uiSearchArrSuggestOverlay: boolean;
    uiSearchDep1SuggestOverlay: boolean;
    uiSearchArr1SuggestOverlay: boolean;
    uiSearchDepAtOverlay: boolean;
    uiSearchArrAtOverlay: boolean;
    uiSearchPassengersOverlay: boolean;
    uiSearchConciergeOverlay: boolean;
    profile: UserModel;
    searchTimeWindow: IFilterTimeWindow;
    isCurrentUserAdmin: boolean;
    isCurrentUserTravelAssistant: boolean;
    isCurrentUserManager: boolean;
    searchPassengers: PassengerModel[];
    searchType: SEARCH_TYPE;
    organization: OrganizationModel;
    isRebookingSearch: boolean;
  };

type State = {
  isSearchButtonLoading: boolean;
};
class SearchBarConn extends React.Component<Props, State> {
  readonly state: State = {
    isSearchButtonLoading: false,
  };

  public componentDidMount() {
    if (
      !isNil(this.props.profile) &&
      !this.props.isCurrentUserTravelAssistant &&
      isEmpty(this.props.searchPassengers)
    ) {
      const firstPassenger: PassengerModel = new PassengerModel();
      firstPassenger.firstName = this.props.profile.firstName;
      firstPassenger.lastName = this.props.profile.lastName;
      firstPassenger.userId = this.props.profile.id;
      firstPassenger.role = this.props.profile.role;
      this.props.dispatch(searchActions.setSearchPassengers([firstPassenger]));
    }
  }

  private handleOverlays = (overlayStr: string, modeOpen: boolean = true) => {
    const { dispatch } = this.props;
    // Close all overlays
    dispatch(setUiSearchArrAtOverlay(false));
    dispatch(setUiSearchDepAtOverlay(false));
    dispatch(setUiSearchArrSuggestOverlay(false));
    dispatch(setUiSearchDepSuggestOverlay(false));
    dispatch(setUiSearchArr1SuggestOverlay(false));
    dispatch(setUiSearchDep1SuggestOverlay(false));
    dispatch(setUiSearchPassengersOverlay(false));
    dispatch(setUiSearchConciergeOverlay(false));
    if (modeOpen) {
      switch (overlayStr) {
        case 'arrAt':
          dispatch(setUiSearchArrAtOverlay(true));
          break;
        case 'depAt':
          dispatch(setUiSearchDepAtOverlay(true));
          break;
        case 'arrSuggest':
          dispatch(setUiSearchArrSuggestOverlay(true));
          break;
        case 'depSuggest':
          dispatch(setUiSearchDepSuggestOverlay(true));
          break;
        case 'arr1Suggest':
          dispatch(setUiSearchArr1SuggestOverlay(true));
          break;
        case 'dep1Suggest':
          dispatch(setUiSearchDep1SuggestOverlay(true));
          break;
        case 'passengersSelector':
          dispatch(setUiSearchPassengersOverlay(true));
          break;
        case 'concierge':
          dispatch(setUiSearchConciergeOverlay(true));
          break;
      }
    }
  };

  private onFetchLocationDetail = (isDep: boolean, index: number, location: any) => {
    console.log('index: ')
    console.log(index)
    this.props.dispatch(searchActions.fetchLocationDetailAsync.request({ isDep, index, location }));
  };

  private onSetSearchDepAt = (val) => {
    this.props.dispatch(searchActions.setSearchDepAt(val));
  };

  private onSetSearchArrAt = (val) => {
    this.props.dispatch(searchActions.setSearchArrAt(val));
  };

  private handleStartSearch = () => {
    const { search } = this.props;
    this.setState({ isSearchButtonLoading: true });
    this.props.dispatch(
      searchActions.startSearchAsync.request({
        onSuccess: (searchRes) => {
          if (search.searchType === SEARCH_TYPE.RENTAL) {
            window.location.href = `${API_URL.DEFAULT}/api/rental/searches/${searchRes.id}`;
          } else if (this.props.isSearchingForOnlyHotel) {
            navigate(`${ROUTES.HOTELS}${searchRes.id}`);
          } else {
            navigate(`${ROUTES.TRIPS.OUTWARD}${searchRes.id}`);
          }
        },
        onError: (err) => {
          this.setState({ isSearchButtonLoading: false });
          return cond(baseErrorCond)(err);
        },
        search,
      }),
    );
  };

  public render() {
    const { dispatch, searchType, profile, organization } = this.props;

    return (
      <>
        <BENotificationBar currentPage="Home" />

        <aside className="search-bar">
          <div className="search-bar-inner">
            {this.props.appContext.isMediaBPNotMobile && (
              <Logo
                language={profile?.preference?.language}
                orgLogoPath={organization?.logoPath}
                orgId={organization?.id}
              />
            )}

            <RebookingBanner />

            {
              // for rebooking searches we should not allow users to change the search type and filters
              !this.props.isRebookingSearch && (
                <>
                  <SearchOptions />
                  <SearchBarFilter
                    searchType={searchType}
                    onSetSearchHotelRoomsType={(val) =>
                      dispatch(searchActions.setSearchHotelRoomsType(val))
                    }
                    currentRoomType={this.props.search.roomType}
                  />
                </>
              )
            }

            <SearchBarRoute
              onSetUiSearchSuggestDepOverlay={this.handleOverlays}
              onSetUiSearchSuggestArrOverlay={this.handleOverlays}
              onSetUiSearchSuggestDep1Overlay={this.handleOverlays}
              onSetUiSearchSuggestArr1Overlay={this.handleOverlays}
              onSetIsSearchingForOnlyHotel={(val) =>
                dispatch(settingsActions.setIsSearchingForOnlyHotel(val))
              }
              onSetIsSearchingForOnlyOutbound={(val) =>
                dispatch(settingsActions.setIsSearchingForOnlyOutbound(val))
              }
              isSearchingForOnlyHotel={this.props.isSearchingForOnlyHotel}
              depOutboundSuggestions={this.props.depOutboundSuggestions}
              arrOutboundSuggestions={this.props.arrOutboundSuggestions}
              depInboundSuggestions={this.props.depInboundSuggestions}
              arrInboundSuggestions={this.props.arrInboundSuggestions}
              uiSearchSuggestDepOverlay={this.props.uiSearchDepSuggestOverlay}
              uiSearchSuggestArrOverlay={this.props.uiSearchArrSuggestOverlay}
              uiSearchSuggestDep1Overlay={this.props.uiSearchDep1SuggestOverlay}
              uiSearchSuggestArr1Overlay={this.props.uiSearchArr1SuggestOverlay}
              onFetchLocationDetail={this.onFetchLocationDetail}
              homeLocations={
                hasEmptyValues(profile?.preference?.locationHome)
                  ? null
                  : profile.preference.locationHome
              }
              workLocations={
                hasEmptyValues(profile?.preference?.locationWork)
                  ? null
                  : profile.preference.locationWork
              }
              favoriteLocations={verifyProperty([], this.props.profile, [
                'preference',
                'locationFavorite',
              ])}
              profile={profile}
              transportInputDisabled={this.props.isRebookingSearch}
            />

            <div
              className={`search-bar-spacer ${
                searchType === SEARCH_TYPE.ALL || searchType === SEARCH_TYPE.OUTBOUND
                  ? 'is--small'
                  : ''
              }`}
            />

            <SearchBarDateTime
              onSetSearchDepAt={this.onSetSearchDepAt}
              onSetSearchArrAt={this.onSetSearchArrAt}
              onSetUiSearchDepAtOverlay={this.handleOverlays}
              onSetUiSearchArrAtOverlay={this.handleOverlays}
              onSetIsSearchingForOnlyOutbound={(val) =>
                dispatch(settingsActions.setIsSearchingForOnlyOutbound(val))
              }
              isSearchingForOnlyOutbound={this.props.isSearchingForOnlyOutbound}
              isSearchingForOnlyHotel={this.props.isSearchingForOnlyHotel}
              uiSearchDepAtOverlay={this.props.uiSearchDepAtOverlay}
              uiSearchArrAtOverlay={this.props.uiSearchArrAtOverlay}
              searchTimeWindow={this.props.searchTimeWindow}
              disableToggleButton={this.props.isRebookingSearch}
              disableDepDatePicker={this.props.search?.rebooking?.originalFareDirection === DIRECTION.INBOUND}
              disableArrDatePicker={this.props.search?.rebooking?.originalFareDirection === DIRECTION.OUTWARD}
            />
            <SearchBarPassengers
              onSetUiSearchPassengersOverlay={this.handleOverlays}
              uiSearchPassengersOverlay={this.props.uiSearchPassengersOverlay}
              searchPassengers={this.props.searchPassengers}
              isCurrentUserAdmin={this.props.isCurrentUserAdmin}
              isCurrentUserManager={this.props.isCurrentUserManager}
              isCurrentUserTravelAssistant={this.props.isCurrentUserTravelAssistant}
              organizationId={this.props.profile.organizationId}
              disabled={this.props.isRebookingSearch}
            />
            <div className="search-bar-button">
              <SearchSubmitButton
                onStartSearch={() => this.handleStartSearch()}
                isLoading={this.state.isSearchButtonLoading}
              />
            </div>
          </div>
          <div className="search-bar-inner">
            <SearchBarConcierge
              uiSearchConciergeOverlay={this.props.uiSearchConciergeOverlay}
              onSetUiSearchConciergeOverlay={this.handleOverlays}
              profile={this.props.profile}
            />
          </div>
        </aside>
      </>
    );
  }
}
const mapStateToProps = (state: IRootState) => ({
  searchType: selectors.search.searchType(state),
  search: selectors.search.search(state.search),
  depCoor: selectors.search.searchDepCoor(state.search),
  arrCoor: selectors.search.searchArrCoor(state.search),
  depAt: selectors.search.searchDepAt(state.search),
  arrAt: selectors.search.searchArrAt(state.search),
  depOutboundSuggestions: selectors.search.searchDepSuggestions(state.search),
  arrOutboundSuggestions: selectors.search.searchArrSuggestions(state.search),
  depInboundSuggestions: selectors.search.searchArr1Suggestions(state.search),
  arrInboundSuggestions: selectors.search.searchArr1Suggestions(state.search),
  isSearchingForOnlyHotel: isSearchingForOnlyHotel(state),
  isSearchingForOnlyOutbound: isSearchingForOnlyOutbound(state),
  uiSearchDepSuggestOverlay: uiSearchDepSuggestOverlay(state),
  uiSearchArrSuggestOverlay: uiSearchArrSuggestOverlay(state),
  uiSearchDep1SuggestOverlay: uiSearchDep1SuggestOverlay(state),
  uiSearchArr1SuggestOverlay: uiSearchArr1SuggestOverlay(state),
  uiSearchDepAtOverlay: uiSearchDepAtOverlay(state),
  uiSearchArrAtOverlay: uiSearchArrAtOverlay(state),
  uiSearchPassengersOverlay: uiSearchPassengersOverlay(state),
  uiSearchConciergeOverlay: uiSearchConciergeOverlay(state),
  searchTimeWindow: state.filters.tripTimeWindowUserFilter,
  profile: state.adminUser.profile,
  isCurrentUserAdmin: adminUserSelectors.isCurrentUserAdmin(state.adminUser),
  isCurrentUserTravelAssistant: adminUserSelectors.isCurrentUserTravelAssistant(state.adminUser),
  isCurrentUserManager: adminUserSelectors.isCurrentUserManager(state.adminUser),
  searchPassengers: searchSelectors.searchPassengers(state.search),
  organization: state.organization.org,
  isRebookingSearch: searchSelectors.isRebookingSearch(state),
});
export default connect(mapStateToProps)(withTranslation()(withAppContext(SearchBarConn)));
