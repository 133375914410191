import { createAction } from 'typesafe-actions';

export const setUiSearchDepSuggestOverlay = createAction(
  'ui/SET_SEARCH_DEP_SUGGEST_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiSearchArrSuggestOverlay = createAction(
  'ui/SET_SEARCH_ARR_SUGGEST_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiSearchDep1SuggestOverlay = createAction(
  'ui/SET_SEARCH_DEP1_SUGGEST_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiSearchArr1SuggestOverlay = createAction(
  'ui/SET_SEARCH_ARR1_SUGGEST_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiSearchDepAtOverlay = createAction(
  'ui/SET_SEARCH_DEP_AT_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiSearchArrAtOverlay = createAction(
  'ui/SET_SEARCH_ARR_AT_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiSearchPassengersOverlay = createAction(
  'ui/SET_SEARCH_PASSENGERS_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiSearchConciergeOverlay = createAction(
  'ui/SET_SEARCH_CONCIERGE_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiMobileFilterOverlay = createAction(
  'ui/SET_MOBILE_FILTER_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiTripChangingTariffsloader = createAction(
  'ui/SET_TRIP_CHANGING_TARIFFS_LOADER',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiMobileNoScrolling = createAction(
  'ui/SET_MOBILE_NO_SCROLLING',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiIsSelectingTrip = createAction(
  'ui/SET_IS_SELECTING_TRIP',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiIsSelectingHotel = createAction(
  'ui/SET_IS_SELECTING_HOTEL',
  (resolve) => (val:boolean) => resolve(val)
);
