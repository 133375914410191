import { JsonObject, JsonProperty } from 'json2typescript';

import { DateConverter } from './converters';
import { SEARCH_TYPE } from '../const/app';
import UserModel from './UserModel';
import PassengerModel from './PassengerModel';
import RebookingModel from './RebookingModel';

@JsonObject('SearchModel')
export default class SearchModel {
  @JsonProperty('id', Number) id: number = -1;
  @JsonProperty('creator_id', Number, true) creatorId: number = -1;
  @JsonProperty('basket_id', Number, true) basketId: number = -1;
  @JsonProperty('dep_at', DateConverter) depAt: Date = undefined;
  @JsonProperty('return_at', DateConverter) arrAt: Date = undefined;
  @JsonProperty('dep_geocoded_name', String) depName: string = '';
  @JsonProperty('arr_geocoded_name', String) arrName: string = '';
  @JsonProperty('dep1_geocoded_name', String) depName1: string = '';
  @JsonProperty('arr1_geocoded_name', String) arrName1: string = '';
  @JsonProperty('dep_lat', Number) depLat: number = -1;
  @JsonProperty('dep_lng', Number) depLng: number = -1;
  @JsonProperty('arr_lat', Number) arrLat: number = -1;
  @JsonProperty('arr_lng', Number) arrLng: number = -1;
  @JsonProperty('dep1_lat', Number) dep1Lat: number = -1;
  @JsonProperty('dep1_lng', Number) dep1Lng: number = -1;
  @JsonProperty('arr1_lat', Number) arr1Lat: number = -1;
  @JsonProperty('arr1_lng', Number) arr1Lng: number = -1;
  @JsonProperty('distance', Number, true) distance: number = -1;
  @JsonProperty('hotel_room_count', Number, true) hotelRoomCount: number = -1;
  @JsonProperty('flight_cabin_class', String, true) flightCabinClass: string = '';
  @JsonProperty('room_type', Number, true) roomType: number = 1;
  @JsonProperty('status', String, true) status: string = '';
  @JsonProperty('passengers', [PassengerModel], true) passengers: PassengerModel[] = [];
  @JsonProperty('creator', UserModel, true) creator: UserModel = undefined;
  @JsonProperty('created_at', DateConverter) createdAt: Date = undefined;
  @JsonProperty('dep_loc_type', String) depLocType: string = undefined;
  @JsonProperty('arr_loc_type', String) arrLocType: string = undefined;
  @JsonProperty('types', [String]) types: string[] = undefined;
  expired: boolean = false;
  searchType: SEARCH_TYPE = SEARCH_TYPE.ALL;
  rentalReturnAtPickup: boolean = true;
  depRentalTime: string = undefined;
  arrRentalTime: string = undefined;
  @JsonProperty('events_status', String, true) eventsStatus: string = undefined;
  @JsonProperty('rebooking', RebookingModel, true) rebooking: RebookingModel = undefined;
}
