import { getType } from 'typesafe-actions';

// Utils
import { merge } from '../util/general';
// Constants
// Actions
import { SearchActions, searchActions } from '../actions';
// Models
import {
  PassengerModel,
  SearchModel,
  SearchNodeModel,
  TravelSearchAggregatorModel,
  EventListingModel,
} from '../models';
// Interfaces

export interface ISearchState {
  id: number;
  depSuggestions: SearchNodeModel[];
  arrSuggestions: SearchNodeModel[];
  currentSearch: SearchModel;
  hotelSearches: TravelSearchAggregatorModel[];
  transportSearches: TravelSearchAggregatorModel[];
  lastWSUpdated: number;
  passengersSuggestions: PassengerModel[];
  eventListing: EventListingModel;
}

const initialState: ISearchState = {
  id: -1,
  depSuggestions: [],
  arrSuggestions: [],
  currentSearch: new SearchModel(),
  hotelSearches: [],
  transportSearches: [],
  lastWSUpdated: -1,
  passengersSuggestions: [],
  eventListing: undefined,
};

export default (state: ISearchState = initialState, action: SearchActions) => {
  switch (action.type) {
    case getType(searchActions.setId):
      return { ...state, id: action.payload };
    case getType(searchActions.setSearchId):
      return { ...state, currentSearch: merge(state.currentSearch, { id: action.payload }) };
    case getType(searchActions.setSearchDep):
      return { ...state, currentSearch: merge(state.currentSearch, { depName: action.payload }) };
    case getType(searchActions.setSearchArr):
      return { ...state, currentSearch: merge(state.currentSearch, { arrName: action.payload }) };
    case getType(searchActions.setSearchDep1):
      return { ...state, currentSearch: merge(state.currentSearch, { depName1: action.payload }) };
    case getType(searchActions.setSearchArr1):
      return { ...state, currentSearch: merge(state.currentSearch, { arrName1: action.payload }) };
    case getType(searchActions.setSearchDepAt):
      return { ...state, currentSearch: merge(state.currentSearch, { depAt: action.payload }) };
    case getType(searchActions.setSearchArrAt):
      return { ...state, currentSearch: merge(state.currentSearch, { arrAt: action.payload }) };
    case getType(searchActions.setSearchDepCoor):
      return {
        ...state, currentSearch: merge(state.currentSearch,
          { depLat: action.payload.lat, depLng: action.payload.lng })
      };
    case getType(searchActions.setSearchArrCoor):
      return {
        ...state, currentSearch: merge(state.currentSearch,
          { arrLat: action.payload.lat, arrLng: action.payload.lng })
      };
    case getType(searchActions.setSearchDep1Coor):
      return {
        ...state, currentSearch: merge(state.currentSearch,
          { dep1Lat: action.payload.lat, dep1Lng: action.payload.lng })
      };
    case getType(searchActions.setSearchArr1Coor):
      return {
        ...state, currentSearch: merge(state.currentSearch,
          { arr1Lat: action.payload.lat, arr1Lng: action.payload.lng })
      };
    case getType(searchActions.setSearchArrLocType):
      return { ...state, currentSearch: merge(state.currentSearch, { arrLocType: action.payload }) };
    case getType(searchActions.setSearchDepLocType):
      return { ...state, currentSearch: merge(state.currentSearch, { depLocType: action.payload }) };
    case getType(searchActions.setSearchDepSuggestions): return { ...state, depSuggestions: action.payload };
    case getType(searchActions.setSearchArrSuggestions): return { ...state, arrSuggestions: action.payload };
    case getType(searchActions.setSearchHotelRoomsCount):
      return { ...state, currentSearch: merge(state.currentSearch, { hotelRoomCount: action.payload }) };
    case getType(searchActions.setSearchHotelRoomsType):
      return { ...state, currentSearch: merge(state.currentSearch, { roomType: action.payload }) };
    case getType(searchActions.setBasketId):
      return { ...state, currentSearch: merge(state.currentSearch, { basketId: action.payload }) };
    case getType(searchActions.setSearch): return { ...state, currentSearch: action.payload };
    case getType(searchActions.clearSearch): return initialState;
    case getType(searchActions.invertSearchLocations): {
      return {
        ...state,
        currentSearch: merge(state.currentSearch, {
          depName: state.currentSearch.arrName,
          depLat: state.currentSearch.arrLat,
          depLng: state.currentSearch.arrLng,
          depLocType: state.currentSearch.arrLocType,
          arrName: state.currentSearch.depName,
          arrLat: state.currentSearch.depLat,
          arrLng: state.currentSearch.depLng,
          arrLocType: state.currentSearch.depLocType,
        }),
      };
    }
    case getType(searchActions.setSearchExpiration):
      return { ...state, currentSearch: merge(state.currentSearch, { expired: action.payload }) };
    case getType(searchActions.addHotelSearchAggregator):
      return { ...state, hotelSearches: [...state.hotelSearches, action.payload] };
    case getType(searchActions.addTransportSearchAggregator):
      return { ...state, transportSearches: [...state.transportSearches, action.payload] };
    case getType(searchActions.clearHotelSearchesAggregators): return { ...state, hotelSearches: [] };
    case getType(searchActions.clearTransportSearchesAggregators): return { ...state, transportSearches: [] };
    case getType(searchActions.setSearchPassengers):
      return { ...state, currentSearch: merge(state.currentSearch, { passengers: action.payload }) };
    case getType(searchActions.setSearchType):
      return { ...state, currentSearch: merge(state.currentSearch, { searchType: action.payload }) };
    case getType(searchActions.setSearchRentalReturnAtPickup):
      return { ...state, currentSearch: merge(state.currentSearch, { rentalReturnAtPickup: action.payload }) };
    case getType(searchActions.setSearchDepRentalTime):
      return { ...state, currentSearch: merge(state.currentSearch, { depRentalTime: action.payload }) };
    case getType(searchActions.setSearchArrRentalTime):
      return { ...state, currentSearch: merge(state.currentSearch, { arrRentalTime: action.payload }) };
    case getType(searchActions.setLastWSUpdated):
      return { ...state, lastWSUpdated: action.payload };
    case getType(searchActions.setPassengersSuggestions):
      return { ...state, passengersSuggestions: action.payload };
    case getType(searchActions.setSearchEventsStatus):
      return { ...state, currentSearch: merge(state.currentSearch, { eventsStatus: action.payload }) };
    case getType(searchActions.setSearchEventListing):
      return { ...state, eventListing: action.payload };
    case getType(searchActions.setSearchFlightCabinClass):
      return { ...state, currentSearch: merge(state.currentSearch, { flightCabinClass: action.payload }) };
    case getType(searchActions.setSearchRebooking):
      return { ...state, currentSearch: merge(state.currentSearch, { rebooking: action.payload }) };
    default: return state;
  }
};
