import { getType } from 'typesafe-actions';
import { uiActions } from '.';
import { generateActionKeys } from '@toolkit/util/state-utils/action.util';

export interface IUIState {
  uiSearchDepSuggestOverlay: boolean;
  uiSearchArrSuggestOverlay: boolean;
  uiSearchDep1SuggestOverlay: boolean;
  uiSearchArr1SuggestOverlay: boolean;
  uiSearchDepAtOverlay: boolean;
  uiSearchArrAtOverlay: boolean;
  uiSearchPassengersOverlay: boolean;
  uiSearchConciergeOverlay: boolean;
  uiMobileFilterOverlay: boolean;
  uiTripChangingTariffsloader: boolean;
  uiMobileNoScrolling: boolean;
  isSelectingTrip: boolean;
  isSelectingHotel: boolean;
  isConfirmingBooking: boolean;
}

const initialState: IUIState = {
  uiSearchDepSuggestOverlay: false,
  uiSearchArrSuggestOverlay: false,
  uiSearchDep1SuggestOverlay: false,
  uiSearchArr1SuggestOverlay: false,
  uiSearchDepAtOverlay: false,
  uiSearchArrAtOverlay: false,
  uiSearchPassengersOverlay: false,
  uiSearchConciergeOverlay: false,
  uiMobileFilterOverlay: false,
  uiTripChangingTariffsloader: false,
  uiMobileNoScrolling: false,
  isSelectingTrip: false,
  isSelectingHotel: false,
  isConfirmingBooking: false,
};

export const UIActions = generateActionKeys<IUIState>(initialState, 'UI');

export default (state: IUIState = initialState, action: any) => {
  switch (action.type) {
    case getType(uiActions.setUiSearchDepSuggestOverlay):
      return { ...state, uiSearchDepSuggestOverlay: action.payload };
    case getType(uiActions.setUiSearchArrSuggestOverlay):
      return { ...state, uiSearchArrSuggestOverlay: action.payload };
    case getType(uiActions.setUiSearchDep1SuggestOverlay):
      return { ...state, uiSearchDep1SuggestOverlay: action.payload };
    case getType(uiActions.setUiSearchArr1SuggestOverlay):
      return { ...state, uiSearchArr1SuggestOverlay: action.payload };
    case getType(uiActions.setUiSearchDepAtOverlay):
      return { ...state, uiSearchDepAtOverlay: action.payload };
    case getType(uiActions.setUiSearchArrAtOverlay):
      return { ...state, uiSearchArrAtOverlay: action.payload };
    case getType(uiActions.setUiSearchPassengersOverlay):
      return { ...state, uiSearchPassengersOverlay: action.payload };
    case getType(uiActions.setUiSearchConciergeOverlay):
      return { ...state, uiSearchConciergeOverlay: action.payload };
    case getType(uiActions.setUiMobileFilterOverlay):
      return { ...state, uiMobileFilterOverlay: action.payload };
    case getType(uiActions.setUiTripChangingTariffsloader):
      return { ...state, uiTripChangingTariffsloader: action.payload };
    case getType(uiActions.setUiMobileNoScrolling):
      return { ...state, uiMobileNoScrolling: action.payload };
    case getType(uiActions.setUiIsSelectingTrip):
      return { ...state, isSelectingTrip: action.payload };
    case getType(uiActions.setUiIsSelectingHotel):
      return { ...state, isSelectingHotel: action.payload };
    case UIActions.isConfirmingBooking:
      return { ...state, isConfirmingBooking: action.payload };
    default:
      return state;
  }
};
